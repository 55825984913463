<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Accept Join Njangi Request</p>
        <v-row>
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="userName"
              ref="userName"
              outlined dense
              label="Search username, phone number, unique code..."
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
              @click="getUsers()"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedUser"
              ref="selectedUser"
              :items="users"
              item-text="applicant_info"
              item-value="id"
              outlined dense
              label="Select a user"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedNjangiGroup"
              ref="selectedNjangiGroup"
              outlined
              dense
              :items="njangi_groups"
              item-text="njangi_info"
              item-value="id"
              label="Select Njangi Group"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="AddUserNjangi()"
        >
          Add User Njangi
        </v-btn>

      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
      userName: "",
      selectedUser: null,
      selectedNjangiGroup: null,
      users: [],
      njangi_groups: [],

      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    }
  },
  
  mounted(){
      // executes these after the page has been mounted
      this.getNjangiGroups()
      document.title = "Manager: Njangi | Add User To Njangi"
  },

  methods:{
    getUsers(){
      axios
        .get('/api/v1/manager/njangi/get/applicant/'+this.userName+'/')
        .then(response => {
          this.users = response.data  // get the data and fill into users
          console.log(this.users)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async getNjangiGroups(){
      this.loading = true
      console.log(this.loading)
      await axios
        .get('/api/v1/manager/njangi/get/groups/')
        .then(response => {
          this.njangi_groups = response.data  // get the data and fill into campaigns
          console.log(this.njangi_groups)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },

    async AddUserNjangi(){
      if(!this.selectedNjangiGroup){
          this.$store.commit('setSnackBarMessage', "Please select the njangi group")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.selectedNjangiGroup.focus()  // this causes a an auto fucos to the element
      }
      if(!this.selectedUser){
          this.$store.commit('setSnackBarMessage', "Please select the client")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.selectedUser.focus()  // this causes a an auto fucos to the element
      }
      else {
          this.loading = true

          let formData = new FormData()
            formData.append('njangi_id', this.selectedNjangiGroup)
            formData.append('application_id', this.selectedUser)

          await axios
              .post('/api/v1/manager/njangi/accept/group/application/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
              .then(response => {
                  console.log(response.data)
                  this.$store.commit('setSnackBarMessage', response.data[0].text)
                  this.$store.commit('setSnackBarColor', response.data[0].color)
                  this.$store.commit('activateSnackBar', true)
                  this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                  if (response.data[0].category === "success"){
                    let router = this.$router
                    setTimeout(function(){
                        router.push('/njangi/view/groups')
                    }, 4000)
                  }
              })
              .catch(error => {
                  if (error.response){
                      this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                      this.$store.commit('setSnackBarColor', "red darken-4")
                      this.$store.commit('activateSnackBar', true)
                  }else{
                      this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                      this.$store.commit('setSnackBarColor', "red darken-4")
                      this.$store.commit('activateSnackBar', true)
                  }
              })

          this.loading = false
      }
    },
  },
}
</script>
